import moment from 'moment-timezone'
import React, { forwardRef, useContext, useState } from 'react'
import DatePicker from 'react-datepicker'

import './react-datepicker.css'
import { AuthContext } from 'app/modules/Auth/AuthContext'

function remapDateToNumber(update: Date | null) {
    return update !== null && typeof update !== 'string' && typeof update !== 'number' ? moment(update).valueOf() : update
}
function remapDateToNumberInTZ(update: Date | null, tz: string) {
    return update !== null && typeof update !== 'string' && typeof update !== 'number' ? moment.tz(update, tz).valueOf() : update
}

export default function DateRangePicker(props: {
    startDate: number
    endDate: number
    disabled: boolean
    selectsRange: boolean
    onDateChange: (date: (number | null)[]) => void
}) {
    const [dateRange, setDateRange] = useState<(number | null)[]>([props.startDate, props.endDate])
    const { currentOrganization } = useContext(AuthContext)

    const [startDate, endDate] = dateRange

    if (startDate !== props.startDate || endDate !== props.endDate) {
        setDateRange([props.startDate, props.endDate])
    }

    const ref = React.createRef<HTMLDivElement>()

    const CustomDateInput = forwardRef<HTMLDivElement>((props, ref) => (
        <div className="d-flex flex-center" ref={ref}>
            <span
                className={`font-weight-bolder cursor-pointer font-size-h5 ${
                    // @ts-ignore
                    props.isWhite ? 'text-white text-hover-specta-gray' : 'text-dark text-hover-dark-65'
                }`}
                onClick={() => {
                    // @ts-ignore
                    props.onClick()
                }}>
                <i
                    className="
far fa-calendar-alt icon-md mr-2 text-dark"
                />
                {startDate &&
                    endDate &&
                    moment(startDate).startOf('day').valueOf() !== moment(endDate).startOf('day').valueOf() &&
                    moment(startDate).format('D MMM, YYYY') + ' - ' + moment(endDate).format('D MMM, YYYY')}
                {startDate &&
                    endDate &&
                    moment(startDate).startOf('day').valueOf() === moment(endDate).startOf('day').valueOf() &&
                    moment(startDate).format('D MMM, YYYY')}
                {!startDate && !endDate && moment().format('D MMM, YYYY')}
                {startDate && !endDate && moment(startDate).format('D MMM, YYYY')}
            </span>
        </div>
    ))

    CustomDateInput.displayName = 'CustomDateInput'
    return (
        <DatePicker
            disabled={props.disabled}
            shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
            selectsRange={props.selectsRange}
            startDate={startDate ? new Date(startDate) : null}
            endDate={endDate ? new Date(endDate) : null}
            onChange={(update, e) => {
                e?.preventDefault()
                if (Array.isArray(update)) {
                    setDateRange([remapDateToNumber(update[0]), remapDateToNumber(update[1])])
                    props.onDateChange([
                        remapDateToNumberInTZ(update[0], currentOrganization?.timezone!),
                        remapDateToNumberInTZ(update[1], currentOrganization?.timezone!)
                    ])
                } else {
                    setDateRange([remapDateToNumber(update), null])
                    props.onDateChange([remapDateToNumberInTZ(update, currentOrganization?.timezone!), null])
                }
            }}
            customInput={<CustomDateInput ref={ref} />}
            withPortal
        />
    )
}
