import { Formik } from 'formik'
import { isEqual } from 'lodash'
import moment from 'moment-timezone'
import type React from 'react'
import { useContext, useEffect, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import DateRangePicker from '../../../../components/DateRangePicker'
import MainActionButton from '../../../../components/buttons/MainActionButton'
import { AuthContext } from '../../../Auth/AuthContext'
import { SubscriptionContext } from '../../../Subscriptions/SubscriptionContext'
import { ReportsContext } from '../../ReportsContext'
import DateRangeOptions from './DateRangeOptions'
import { dateOptions } from './data'
import { prepareFilter } from './logic'
import { FilterRow, LeftColumn, MiddleColumn, RightColumn } from './style'
import type { FilterType } from './types'

export function ReportsFilter({
    dateFilter = ['Today', 'Yesterday', 'This month', 'Last month'],
    defaultDateFilter = 'Today',
    children
}: {
    dateFilter?: FilterType['filter']['dateFilter'][]
    defaultDateFilter?: FilterType['filter']['dateFilter']
    children?: React.ReactNode
}) {
    const { queryParams, setQueryParams, listLoading } = useContext(ReportsContext)
    const { currentOrganization } = useContext(AuthContext)

    const reportsUIProps = useMemo(() => {
        return {
            queryParams: queryParams,
            setQueryParams: setQueryParams
        }
    }, [queryParams, setQueryParams])

    const dateOptionsForOrg = dateOptions(currentOrganization!.timezone)
    const initialValues: FilterType['filter'] = {
        name: '',
        dateFilter: defaultDateFilter,
        startDate: moment.tz(queryParams.filter.startDate, currentOrganization!.timezone).valueOf(),
        endDate: moment.tz(queryParams.filter.endDate, currentOrganization!.timezone).valueOf(),
        dateFilterType: dateOptionsForOrg.find(option => option.dateFilter === defaultDateFilter)!.dateFilterType || 'date'
    }

    useEffect(() => {
        if (defaultDateFilter) {
            const newQueryParams = prepareFilter(
                reportsUIProps.queryParams,
                dateOptionsForOrg.find(option => option.dateFilter === defaultDateFilter)!
            )
            if (!isEqual(newQueryParams, reportsUIProps.queryParams)) {
                reportsUIProps.setQueryParams(newQueryParams)
                initialValues.startDate = moment.tz(newQueryParams.filter.startDate, currentOrganization!.timezone).valueOf()
                initialValues.endDate = moment.tz(newQueryParams.filter.endDate, currentOrganization!.timezone).valueOf()
            }
        }
    }, [defaultDateFilter])

    const applyFilter = (values: FilterType['filter']) => {
        console.log('APPLYING FILTER')
        const newQueryParams = prepareFilter(reportsUIProps.queryParams, values)

        if (!isEqual(newQueryParams, reportsUIProps.queryParams)) {
            reportsUIProps.setQueryParams(newQueryParams)
        }
    }

    const filteredDateOptions = dateOptionsForOrg.filter(option => dateFilter.includes(option.dateFilter))

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={values => {
                applyFilter(values as FilterType['filter'])
            }}>
            {({ values, handleSubmit, setFieldValue }) => (
                <FilterRow className="row">
                    <LeftColumn className="col-lg-4">{children}</LeftColumn>
                    <MiddleColumn className="col-lg-4">
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <DateRangePicker
                                disabled={listLoading}
                                selectsRange={true}
                                startDate={values.startDate}
                                endDate={values.endDate}
                                onDateChange={(date: (number | null)[]) => {
                                    const SECOND_DATE_SELECTED = !!date[1]

                                    setFieldValue('startDate', date[0])
                                    setFieldValue('endDate', date[1])
                                    if (moment(date[1]).diff(moment(date[0])) > 1) {
                                        setFieldValue('dateFilterType', 'period')
                                    } else {
                                        setFieldValue('dateFilterType', 'date')
                                    }

                                    if (SECOND_DATE_SELECTED) {
                                        handleSubmit()
                                    }
                                }}
                            />
                        </div>
                    </MiddleColumn>
                    <RightColumn className="col-lg-4">
                        <DateRangeOptions
                            options={filteredDateOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleSubmit={handleSubmit}
                            disabled={listLoading}
                        />
                    </RightColumn>
                </FilterRow>
            )}
        </Formik>
    )
}
